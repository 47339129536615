const statementFields = {
  fields: [
    {
      name: 'Your Name(s)',
      _type: 'textField',
      fieldOptions: {
        adminLabel: 'name',
        required: true,
        // halfWidth: true,
      },
      _key: 'name',
    },
    {
      name: 'Your Organization',
      _type: 'textField',
      fieldOptions: {
        adminLabel: 'organization',
        // halfWidth: true,
      },
      _key: 'organization',
    },
    {
      name: 'Email',
      _type: 'emailField',
      fieldOptions: {
        adminLabel: 'email',
        required: true,
      },
      _key: 'email',
    },
    {
      name: 'Country',
      _type: 'textField',
      fieldOptions: {
        adminLabel: 'country',
      },
      _key: 'country',
    },
    {
      name: 'Region',
      _type: 'textField',
      fieldOptions: {
        adminLabel: 'region',
      },
      _key: 'region',
    },
    {
      name: 'City',
      _type: 'textField',
      fieldOptions: {
        adminLabel: 'city',
      },
      _key: 'city',
    },
    {
      name: 'publishState',
      _type: 'hidden',
      value: 'newly-submitted',
      _key: 'publishState',
    },
    {
      name: `Please check if you'd like to join our collaboration email list.`,
      _type: 'checkBox',
      fieldOptions: {
        adminLabel: 'emailList',
      },
      _key: 'email-list',
    },
  ],
};

export default statementFields;
