import React from 'react';
import styled from 'styled-components';

import ButtonStyles from '../ButtonStyles';
import { media } from '../theme';

const Submit = ({ children, classNames }) => {
  return <Button type="submit" value={children} className={(classNames, 'button')} />;
};

const Button = styled.input`
  ${ButtonStyles}
  justify-self: start;
  margin-left: 0;
  margin-right: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  ${media.break`
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0;
  margin-right: 0;
  `}
`;

export default Submit;
