import React from 'react';
import styled from 'styled-components';

import Content from '../Content';
import { media } from '../theme';

import { useForm, FormContextProvider } from './formUtils';
import FieldSwitcher from './FieldSwitcher';
import Submit from './Submit';

const Form = props => {
  const { title, description, fields, submitText, successMessage } = props;

  const initialInputs = {};
  fields.forEach(({ name, fieldOptions, _type, value }) => {
    const fieldName = fieldOptions?.adminLabel ? fieldOptions.adminLabel : name;
    if (_type === 'checkBox') {
      initialInputs[fieldName] = value ? value : false;
    } else {
      initialInputs[fieldName] = value ? value : '';
    }
  });
  // console.log(initialInputs);

  const { success, setSuccess, inputs, loading, setLoading, errors, handleChange } =
    useForm(initialInputs);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    // console.log(inputs);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ ...inputs, 'form-name': title, formId: title }).toString(),
    })
      .then(() => {
        console.log('form successful');
        setSuccess(true);
        setLoading(false);
      })
      .catch(error => {
        console.log('form error: ', error);
        setLoading(false);
      });
  }

  return (
    <FormContextProvider value={{ errors, inputs, handleChange }}>
      <FormWrapper
        className="form"
        data-netlify="true"
        netlify-honeypot="honeypotField"
        name={title}
        onSubmit={handleSubmit}
      >
        {/* <h2 className="form__title">{title}</h2> */}
        {description && <Content className="form__description">{description}</Content>}
        {success ? (
          <p className="success">{successMessage}</p>
        ) : (
          <fieldset className="form__fields" disabled={loading} aria-busy={loading}>
            <div className="fieldset-flex-fix">
              {fields.map(field => (
                <FieldSwitcher key={field._key} field={field} />
              ))}
              <label className="honeypot">
                Don't fill this out if you're human: <input name="honeypotField" />
              </label>
              <input type="hidden" name="formId" value={title} />
              <Submit className="form__submit">{submitText || 'Submit'}</Submit>
            </div>
          </fieldset>
        )}
      </FormWrapper>
    </FormContextProvider>
  );
};

const FormWrapper = styled.form`
  padding: 0 2rem;
  fieldset {
    border: 0;
    padding: 0;

    &[disabled] {
      opacity: 0.5;
    }
  }

  @supports (display: grid) {
    .fieldset-flex-fix {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-gap: 1rem;
    }
  }

  .form {
    &__submit {
      justify-self: start;
      -webkit-appearance: none;
      border-radius: 0px;
      grid-column: span 2;
      margin-right: 1rem;
    }
  }

  .field-address {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .complex-field-label {
    font-size: 1.8rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 2.15;
    margin: 0 0 0.5rem;
    text-transform: uppercase;
    display: block;
    grid-column: span 2;
    width: 50%;
    ::after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
    }
  }
  ${media.break`

  `}
  .honeypot {
    display: none;
  }
  .success {
    padding: 1rem;
    background: ${({ theme }) => theme.green};
    color: white;
  }
`;

export default Form;
