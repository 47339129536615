import React from 'react';

import TextField from './TextField';
import PhoneField from './PhoneField';
import TextArea from './TextArea';
import SelectField from './SelectField';
import RadioButtons from './RadioButtons';
import CheckBox from './CheckBox';
import HiddenField from './HiddenField';

const FieldSwitcher = ({ field }) => {
  switch (field._type) {
    case 'textField':
    case 'emailField':
      return <TextField {...field} />;
    case 'phoneField':
      return <PhoneField {...field} />;
    case 'textArea':
      return <TextArea {...field} />;
    case 'selectField':
      return <SelectField {...field} />;
    case 'radioButtons':
      return <RadioButtons {...field} />;
    case 'checkBox':
      return <CheckBox {...field} />;
    case 'hidden':
      return <HiddenField {...field} />;
    default:
      return null;
  }
};

export default FieldSwitcher;
