import React from 'react';
import styled from 'styled-components';

import { useFocusState, useFormContext, getFieldOptions } from './formUtils';
import Label from './Label';

const RadioButtons = ({ name, fieldOptions, options }) => {
  const { focused, handleFocus, handleBlur } = useFocusState();
  const { errors, inputs, handleChange } = useFormContext();
  const { required, halfWidth, description, fieldName } = getFieldOptions({
    ...fieldOptions,
    name,
  });
  const error = errors[fieldName];

  console.log(fieldName);
  // const [checked, setChecked] = useState(null);

  console.log(inputs[fieldName]);
  return (
    <Label className="radiobuttons" htmlFor={fieldName} halfWidth={halfWidth} isFocused={focused}>
      {fieldName}
      {required && '*'}
      <RadioButtonsContainer className="radio-buttons-container" halfWidth={halfWidth}>
        {options.map((option, i) => (
          <span
            className="radio-button-wrapper"
            key={option}
            onClick={handleChange}
            role="radio"
            aria-checked={inputs[fieldName] === option}
            tabIndex={0}
          >
            <input
              onChange={handleChange}
              className="radio-button"
              type="radio"
              checked={inputs[fieldName] === option}
              name={fieldName}
              value={option}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <span className="radio-button-option-label">{option}</span>
          </span>
        ))}
        {description && <p className="field-description">{description}</p>}
      </RadioButtonsContainer>
    </Label>
  );
};

const RadioButtonsContainer = styled.div`
  columns: ${({ halfWidth }) => (halfWidth ? 1 : 2)};
  padding: 1rem;
  .radio-button-wrapper {
    display: block;
    cursor: pointer;
    *:focus {
      outline: 1px;
    }
  }
  .radio-button {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
  }

  .radio-button-option-label {
    display: block;
    font-size: 1.4rem;
    /* font-weight: ${({ theme }) => theme.font.demibold}; */
    padding: 0.5rem;
    padding-left: 2rem;
    position: relative;

    ::before {
      content: '';
      background: transparent;
      border: 1px solid ${({ theme }) => theme.navy};
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .radio-button:focus + span {
    outline-color: ${({ theme }) => theme.navy};
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }
  .radio-button:checked + span::before {
    background: ${({ theme }) => theme.navy};
  }
`;

export default RadioButtons;
