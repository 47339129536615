import React from 'react';
import styled from 'styled-components';

import { useFocusState, useFormContext, getFieldOptions } from './formUtils';
import Label from './Label';

const CheckBoxes = ({ name, fieldOptions, options }) => {
  const { focused, handleFocus, handleBlur } = useFocusState();
  const { errors, inputs, handleChange } = useFormContext();
  const { required, halfWidth, description, fieldName } = getFieldOptions({
    ...fieldOptions,
    name,
  });
  const error = errors[fieldName];

  return (
    <CheckBoxLabel
      className="checkbox"
      htmlFor={fieldName}
      halfWidth={halfWidth}
      isFocused={focused}
      onClick={handleChange}
    >
      <input
        onChange={handleChange}
        className="checkbox-input"
        type="checkbox"
        checked={inputs[fieldName]}
        name={fieldName}
        value={inputs[fieldName]}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <span className="radio-button-option-label">
        {name}
        {required && '*'}
      </span>

      {description && <p className="field-description">{description}</p>}
    </CheckBoxLabel>
  );
};

const CheckBoxLabel = styled(Label)`
  display: flex;
  align-items: center;
  input {
    flex: 0 0 auto;
    width: auto;
    margin-top: 0;
    margin-right: 1rem;
  }
`;

export default CheckBoxes;
