import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import statementFields from './statementFields';
import Form from './Form';

const StatementForm = () => {
  const data = useStaticQuery(graphql`
    {
      sanityStatementFormSettings {
        _rawDescription(resolveReferences: { maxDepth: 10 })
        title
        submitText
        successMessage
      }
    }
  `);
  return <Form {...statementFields} {...data.sanityStatementFormSettings} />;
};

export default StatementForm;
