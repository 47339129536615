import React from 'react';
import Label from './Label';
import { useFormContext, useFocusState, getFieldOptions } from './formUtils';
import ErrorMessage from './ErrorMessage';

const TextArea = ({ name, fieldOptions }) => {
  const { focused, handleFocus, handleBlur } = useFocusState();
  const { errors, inputs, handleChange } = useFormContext();
  const { required, halfWidth, description, fieldName } = getFieldOptions({
    ...fieldOptions,
    name,
  });
  const error = errors[fieldName];
  return (
    <Label className="field-text" isFocused={focused} htmlFor={fieldName} halfWidth={halfWidth}>
      {name}
      {required && '*'}
      <textarea
        className="text-area"
        name={fieldName}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputs[fieldName]}
        onChange={handleChange}
        rows={3}
        required={required}
      />
      {description && <p className="field-description">{description}</p>}
      {error && <ErrorMessage className="error--text-field" {...error} />}
    </Label>
  );
};

export default TextArea;
