import React from 'react';
import { getFieldOptions } from './formUtils';

const TextField = ({ name, fieldOptions, value }) => {
  const { fieldName } = getFieldOptions({
    ...fieldOptions,
    name,
  });

  return <input className="hidden" type="hidden" name={fieldName} value={value || null} />;
};

export default TextField;
