import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { media } from './theme';

const SignersList = () => {
  const data = useStaticQuery(graphql`
    {
      allSanitySubmission(filter: { publishState: { show: { eq: true } } }) {
        edges {
          node {
            id
            name
            organization
          }
        }
      }
    }
  `);
  return (
    <>
      <StyledSignersList className="partners-list">
        {data.allSanitySubmission.edges.map(({ node: { id, name, individual, organization } }) => (
          <li className="signer" key={id} onMouseDown={() => false} onSelectStart={() => false}>
            <span className="signer__name">
              {name}
              {individual && organization && (
                <Asterisk
                  style={{
                    transform: 'translateY(-10px)',
                    paddingLeft: '1px',
                    marginBottom: '5px',
                    height: '16px',
                    verticalAlign: 'top',
                  }}
                >
                  *
                </Asterisk>
              )}
            </span>
            {organization && <span className="signer__organization">{organization}</span>}
          </li>
        ))}
      </StyledSignersList>
      {/* <Asterisk style={{ padding: '0 2rem', margin: '1.8rem 0' }}>*Does not denote organizational support.</Asterisk> */}
    </>
  );
};

const Asterisk = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.navy};
  font-size: 1.4rem;
  text-align: center;
`;

const StyledSignersList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  ${media.break`
    columns: 2;
  `}
  .signer {
    margin: 1rem 0;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding-bottom: 1.3rem;

    ${media.break`
      flex: 0 0 50%;
    `}
    &__name {
      color: ${({ theme }) => theme.red};
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: 2.4rem;
      display: block;
    }
    &__organization {
      display: block;
      font-style: italic;
      color: ${({ theme }) => theme.navy};
    }
    &::after {
      width: 50%;
      height: 1px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      background: ${({ theme }) => theme.mint};
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default SignersList;
