import React from 'react';

import { useFocusState, useFormContext, getFieldOptions } from './formUtils';
import states from './states';
import Label from './Label';

const SelectField = ({ name, fieldOptions, options, prepopulatedOptions }) => {
  const { focused, handleFocus, handleBlur } = useFocusState();
  const { errors, inputs, handleChange } = useFormContext();
  const { required, halfWidth, description, fieldName } = getFieldOptions({
    ...fieldOptions,
    name,
  });
  // const error = errors[fieldName];

  const selectOptions = getOptions(options, prepopulatedOptions);

  return (
    <Label
      className="select-field-label"
      htmlFor={fieldName}
      halfWidth={halfWidth}
      isFocused={focused}
    >
      {name}
      {required && '*'}
      <select
        className="select-field"
        name={fieldName}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={inputs[fieldName]}
        required={required}
      >
        <option value={''}></option>
        {selectOptions.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {description && <p className="field-description">{description}</p>}
    </Label>
  );
};

function getOptions(options, prepopulatedOptions) {
  if (prepopulatedOptions) {
    if (prepopulatedOptions[0] === 'states') {
      return states;
    }
  }
  return options.map(option => ({
    label: option.label ? option.label : option,
    value: option.value ? option.value : option,
  }));
}

export default SelectField;
