import React from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import styled from 'styled-components';

import { media } from './theme';
import Form from './Form';

import SignersList from './SignersList';
import StatementForm from './StatementForm';
import Button from './Button';

const customSerializers = {
  types: {
    block: props => {
      const { node, children } = props;
      const { style = 'normal' } = node;
      if (style === 'large') {
        return <p className="large-text">{children}</p>;
      }
      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    button: ({ node }) => {
      return <Button {...node} />;
    },
    form: ({ node }) => {
      return <Form {...node} />;
    },
    statementForm: () => {
      return <StatementForm />;
    },
    partnersList: () => {
      return <SignersList />;
    },
    altImage: ({ node }) => {
      const link = node.link || false;

      return link ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="content-image-wrapper">
          <img className="content-image" src={node.asset.url} alt={node.alt || ''} style={{ width: 'auto', display: 'initial' }} />
        </a>
      ) : (
        <p className="content-image-wrapper">
          <img className="content-image" src={node.asset.url} alt={node.alt || ''} style={{ width: 'auto', display: 'initial' }} />
        </p>
      );
    },
    columns: ({ node }) => {
      return (
        <div className="columns">
          <Content className="column">{node.column1}</Content>
          <Content className="column">{node.column2}</Content>
        </div>
      );
    },
    listBlock: ({ node }) => {
      return (
        <StyledListBlock className="list-block">
          <Content>{node.text}</Content>
        </StyledListBlock>
      );
    },
  },
  marks: {
    center: props => {
      return <span className="text-center">{props.children}</span>;
    },
    file: ({
      children,
      mark: {
        asset: { url },
      },
    }) => {
      return (
        <a class="file-link" href={url} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    },
    link: ({ children, mark }) => {
      const { externalUrl, url } = mark;
      if (externalUrl) {
        return (
          <a href={externalUrl} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      }
      return <Link to={`/${url?.slug?.current || null}`}>{children}</Link>;
    },
  },
};

const Content = React.forwardRef(({ className, children, style }, ref) => {
  return (
    <Container style={style} className={classNames('block-content', className)} ref={ref}>
      {children && (
        <BlockContent
          blocks={children}
          projectId="c4x53r5y"
          dataset="production"
          serializers={customSerializers}
          className="block-content-wrapper"
          renderContainerOnSingleChild={true}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  padding: 1rem;
  blockquote {
    font-family: ${({ theme }) => theme.font.special};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.teal};
    text-align: center;
    font-size: 2.4rem;
    margin: 1rem 0;
    line-height: 1.5;
    ${media.break`
      font-size: 3.6rem;
    `}
  }
  p,
  li {
    color: ${({ theme }) => theme.dark};
    font-size: 1.8rem;
    line-height: 1.5;
    padding: 0 2rem;
    ${media.break`
      font-size: 1.8rem;
    `}
    &.large-text {
      color: ${({ theme }) => theme.teal};
      font-size: 2.2rem;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    padding: 0 2rem;
    > span {
      display: block;
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.font.special};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.teal};
    text-align: center;
    font-size: 2.4rem;
    margin: 1rem 0;
    line-height: 1.5;
    ${media.break`
    font-size: 3.6rem;
    `}
  }
  h2 {
    font-family: ${({ theme }) => theme.font.special};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.navy};
    margin: 1rem 0;
    font-size: 2.2rem;
    ${media.break`
      font-size: 3.2rem;
    `}
  }

  .columns {
    ${media.break`
      display: flex;
      align-items: center;
      .column {
        flex: 0 0 50%;
        padding: 1rem 2rem;
      }
    `}
  }
`;

const StyledListBlock = styled.div`
  padding: 1rem;
  margin: 1rem 0;

  .block-content-wrapper {
    > * {
      margin-top: 0;
    }
    p,
    li {
      color: ${({ theme }) => theme.red};
      font-size: 2.4rem;
      font-weight: ${({ theme }) => theme.font.bold};
      text-align: center;
    }
    ${media.break`
    columns: 2;
    `}
  }
`;

export default Content;
