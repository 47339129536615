import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ButtonStyles from './ButtonStyles';
import { media } from './theme';

const Button = ({ text, url, externalUrl }) => {
  if (externalUrl) {
    return (
      <StyledButton href={externalUrl} target="_blank" rel="noopener noreferrer">
        {text}
      </StyledButton>
    );
  }
  return <StyledLink to={`/${url?.slug?.current || null}`}>{text}</StyledLink>;
};

const StyledButton = styled.a`
  ${ButtonStyles}
  display: inline-block;
`;
const StyledLink = styled(Link)`
  ${ButtonStyles}
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  ${media.break`
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export default Button;
